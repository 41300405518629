import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Helpers } from '../../../helpers';
import { GlobalEventsManagerService } from '../../../global-event-manager-service.service';
import { UserService } from '../../../auth/_services/user.service';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ThemeService } from '../../../_services/theme.service';

declare let mLayout: any;
declare var $: any;
@Component({
    selector: "app-aside-nav",
    templateUrl: "./aside-nav.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class AsideNavComponent implements OnInit, AfterViewInit, OnDestroy {
    dashboardMenuLinks: boolean = false;
    authenticationSubscription: any;
    selectedClasses:any;
    LoggedInUserEmail: any;
    showNavBar: boolean = false;
    showManagerData: boolean = false;
    showMemberData: boolean = false;
    showPentesterData: boolean = false;
    subs: Subscription[] = [];
    showSuperAdminBar: boolean = false;
    showTesterBar: Boolean = false;
    isReportsMenuOpen = false;
    isSettingMenuOpen = false;
    isDarkMode: boolean = false;
    currentClasses: any;
    lightModeClasses = {headerColor:"lightbg",topLeft:"m-brand--skin-light",leftMenu:"m-aside-left--skin-light",leftMenuIcons:"m-menu__link-icon-light",avgraph:"grey-section-light",companyRiskCard:"bglight",noneLeftBorder:"non-left-border-light",onlyRightBorder:"border-right-light",generalText:"border-right-light",graphHeadingStyling:"graphHeadingStylinglight"};
    darkModeClasses = {headerColor:"darkblackgb",topLeft:"m-brand--skin-dark-blk darkblackgb",leftMenu:"m-aside-left--skin-dark-blk",leftMenuIcons:"m-menu__link-icon",avgraph:"grey-section-dark",companyRiskCard:"bgblack",noneLeftBorder:"non-left-border-blk",onlyRightBorder:"border-right-dark",generalText:"border-right-light",graphHeadingStyling:"graphHeadingStylingdark"};

    constructor(private themeService: ThemeService,private router: Router,private userService: UserService, private _globalEventService: GlobalEventsManagerService) {
        this.subscribeToAuthentication();
        //console.log(this.isDarkMode);
    }
    toggleReportsMenu() {
        this.isReportsMenuOpen = !this.isReportsMenuOpen;
      }
    toggleSettingMenu() {
        this.isSettingMenuOpen = !this.isSettingMenuOpen;
      }
    ngOnInit() {
        $('#body').addClass('m-brand--minimize m-aside-left--minimize');
        if(this.router.url ==='/index'){
            this.dashboardMenuLinks = true;
        }else{
            this.dashboardMenuLinks = false;
        }
        this.themeService.currentMode$.subscribe(classes => {
            this.currentClasses = classes;
          });
    }
    ngAfterViewInit() {

        mLayout.initAside();

    }
    get currentModeClasses() {
        return this.isDarkMode ? this.darkModeClasses : this.lightModeClasses;
      }
    
    toggleMode() {
        this.isDarkMode = !this.isDarkMode;
        
       // this.selectedClasses = this.isDarkMode ? this.darkModeClasses : this.lightModeClasses;
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            let currentuser = localStorage.getItem('currentUser');
            let Fetchtoken = JSON.parse(currentuser);
            this.themeService.toggleMode(this.isDarkMode);
            let Token = Fetchtoken.token;
            var res = this.userService.userThemeSetting(this.isDarkMode,Token).toPromise();
        }
        
      }
    subscribeToAuthentication() {
        if (localStorage.getItem('isUserLoggedIn') == 'true') {
            // var currentuser = localStorage.getItem('currentUser');
            // var Fetchtoken = JSON.parse(currentuser);

            // var Token = Fetchtoken.token;

            let authenticationSubscription = this.userService.verify().subscribe(res => {

                this.LoggedInUserEmail = res.email;
                this.isDarkMode=res.themeMode;
                this.themeService.toggleMode(this.isDarkMode);
               // this.selectedClasses = this.isDarkMode ? this.darkModeClasses : this.lightModeClasses;
                if (res.tester) {
    
                    this._globalEventService.showTesterBar(true);
                    this._globalEventService.showTesterBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showTesterBar = mode;
                        }
                    });
                } 
                if (res.role == 'Super') {

                    this._globalEventService.showSuperAdminBar(true);
                    this._globalEventService.showSuperAdminBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showSuperAdminBar = mode;
                        }
                    });
                } else if (res.role == 'Admin') {

                    this._globalEventService.showNavBar(true);
                    this._globalEventService.showNavBarEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showNavBar = mode;
                        }
                    });
                } else if (res.role == 'Manager') {

                    this._globalEventService.showManagerData(true);

                    let obs = this._globalEventService.showManegerEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showManagerData = mode;
                        }
                        this.subs.push(obs);
                    });

                } else if (res.role == 'Member') {

                    this._globalEventService.showMemberData(true);
                    let obs = this._globalEventService.showMemberEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showMemberData = mode;
                        }
                        this.subs.push(obs);
                    });
                }
                else if (res.role == 'Pentester') {

                    this._globalEventService.showPentesterData(true);
                    this._globalEventService.showPentesterEmitter.subscribe((mode: any) => {
                        // mode will be null the first time it is created, so you need to igonore it when null
                        if (mode !== null) {
                            this.showPentesterData = mode;
                        }
                    });
                }
                this.subs.push(authenticationSubscription)
            });
        }




    }

    ngOnDestroy(): void {
        this.subs.forEach(s => {
            if (s) {
                s.unsubscribe()
            }
        });

        // this.authenticationSubscription.unsubscribe();
    }

}